@font-face {
  font-family: "Noventa812 Display 014";
  src: url("./fonts/Noventa812Display014.eot");
  src: url("./fonts/Noventa812Display014.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/Noventa812Display014.woff2") format("woff2"),
    url("./fonts/Noventa812Display014.woff") format("woff"),
    url("./fonts/Noventa812Display014.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Blender Pro Medium";
  src: url("./fonts/BlenderPro-Medium.eot");
  src: url("./fonts/BlenderPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/BlenderPro-Medium.woff2") format("woff2"),
    url("./fonts/BlenderPro-Medium.woff") format("woff"),
    url("./fonts/BlenderPro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ACPEX-NORMAL";
  src: url("./fonts/ACPEXNORMALRegular.eot");
  src: url("./fonts/ACPEXNORMALRegular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/ACPEXNORMALRegular.woff2") format("woff2"),
    url("./fonts/ACPEXNORMALRegular.woff") format("woff"),
    url("./fonts/ACPEXNORMALRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$logoFamily: "Play";
$titleFmaily: "Play";
$subTitleFmaily: "Play";
$txtFamily: "Play";

// Size
$tiny: 10px;
$small: 11px;
$medium: 13px;
$large: 14px;
$xlarge: 16px;
$xxlarge: 18px;
$xxxlarge: 60px;

h1,
h2,
h3,
h4,
h5 {
  color: $txt;
  font-weight: 500;
}

#inner-container {
  h1 {
    color: black;
    font-size: $xxlarge;
  }
}

.special-title {
  font-family: "Noventa812 Display 014" !important;
}

h1 {
  font-family: $titleFmaily;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 32px;
  color: white;
  font: 700;
}

h2 {
  font-family: $titleFmaily;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 20px;
  color: white;
  font: 700;
}

h3 {
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  line-height: 16px;
}

h4,
h5 {
  font-family: $subTitleFmaily;
  letter-spacing: 0.5px;
}

p,
li,
a,
pre {
  font-family: $txtFamily;
  letter-spacing: 0px;
  color: white;
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 12px;
}

.ant-notification-notice-description,
.ant-notification-notice-message {
  font-family: $txtFamily;
}

.form-text {
  color: grey;
}

.rotated {
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.rotated-invert {
  writing-mode: vertical-rl;
  transform: rotate(0);
}

.txt-gradient {
  background: -webkit-linear-gradient(left, $primary, $secondary);
  background: -o-linear-gradient(right, $primary, $secondary);
  background: -moz-linear-gradient(right, $primary, $secondary);
  background: linear-gradient(to right, $primary, $secondary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.txt-dark-gradient {
  background: -webkit-linear-gradient(left, $primary-dark, $secondary);
  background: -o-linear-gradient(right, $primary-dark, $secondary);
  background: -moz-linear-gradient(right, $primary-dark, $secondary);
  background: linear-gradient(to right, $primary-dark, $secondary);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blender-medium {
  font-family: "Blender Pro Medium" !important;
  font-weight: 500;
}

.acpex {
  font-family: "ACPEX-NORMAL" !important;
  font-weight: 400;
}

h1.page-title {
  font-family: "ACPEX-NORMAL" !important;
  font-weight: 400;
  font-size: 64px;
  line-height: 72px;
  margin-bottom: 0px;
}

h2.page-subtitle {
  font-family: "Blender Pro Medium" !important;
  font-weight: 700 !important;
  font-size: 40px;
}

h3.card-title {
  font-family: "Blender Pro Medium" !important;
  font-weight: 700 !important;
  font-size: 24px;
}

p.card-big-content {
  font-family: "Blender Pro Medium" !important;
  font-weight: 700 !important;
  font-size: 96px;
}

p.small-title {
  font-family: "Blender Pro Medium" !important;
  font-weight: 500 !important;
  font-size: 30px;
  line-height: 28px;
}

p.content {
  font-family: "Blender Pro Medium" !important;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 28px;
}

p.big-content {
  font-family: "Blender Pro Medium" !important;
  font-weight: 500 !important;
  font-size: 32px;
  line-height: 38.4px;
}

p.small-content {
  font-family: "Blender Pro Medium" !important;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 21.6px;
}

p.option-label {
  font-family: "Blender Pro Medium" !important;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
}

p.hover_title {
  font-family: "Blender Pro Medium" !important;
  font-weight: 700 !important;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;
}

p.hover_content {
  font-family: "Blender Pro Medium" !important;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

/* Media query for medium device devices */
@media (max-width: 1920px) {
  /* Adjust max-width as needed for your target mobile breakpoint */
  h1.page-title {
    font-size: 42px;
    line-height: 48px;
  }

  h1.page-subtitle {
    font-size: 26px;
    line-height: 24px;
  }

  h2.page-subtitle {
    font-size: 26px;
    line-height: 24px;
  }

  p.small-title {
    font-size: 20px;
    line-height: 18.6px;
  }

  p.content {
    font-size: 12px;
    line-height: 18.6px;
  }

  p.option-label {
    font-size: 14px;
    line-height: 16px;
  }

  p.big-content {
    font-size: 21px;
    line-height: 25px;
  }

  p.small-content {
    font-size: 12px;
    line-height: 14.19px;
  }
}

/* Media query for mobile devices */
@media (max-width: 1024px) {
  /* Adjust max-width as needed for your target mobile breakpoint */
  h1.page-title {
    font-size: 24px;
    line-height: 32px;
  }

  h1.page-subtitle {
    font-size: 24px;
    line-height: 36px;
  }

  h2.page-subtitle {
    font-size: 24px;
    line-height: 36px;
  }

  p.small-title {
    font-size: 16px;
    line-height: 17px;
  }

  p.content {
    font-size: 10px;
    line-height: 17px;
  }

  p.option-label {
    font-size: 14px;
    line-height: 16px;
  }

  p.big-content {
    font-size: 17px;
    line-height: 18px;
  }

  p.small-content {
    font-size: 12px;
    line-height: 14.19px;
  }
}

@media only screen and (max-width: 768px) {
  .rotated {
    writing-mode: horizontal-tb;
    transform: rotate(0);
  }
}
