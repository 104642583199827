.ant-modal-close {
  display: none;
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-modal-body {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #0d0d0d !important;
}

.ant-notification {
  border-radius: 20px !important;
}
